import { atom } from "recoil";
import { effects_UNSTABLE } from "hooks/useRecoilLogger";

export interface FilterProps {
  serviceType?: string | null;
  keyword?: string | null;
  rangePrice?: {
    lower: number;
    upper: number;
  };
  rangeYear?: {
    lower: number;
    upper: number;
  };
  orderBy?: "asc" | "desc" | null;
  categoryId?: string | null;
  transmission?: string | null;
  seats?: string | number | null;
  fuel?: string | null;
  position?: {
    latitude?: number | null;
    longitude?: number | null;
  };
  address?: {
    province?: {
      title: string;
      id: number;
    } | null;
    district?: {
      title: string;
      id: number;
    } | null;
  };
  date?: {
    startDate?: string | null;
    endDate?: string | null;
  };
}

export const initialFilter: FilterProps = {
  serviceType: "selfDriving",
  keyword: null,
  rangePrice: { lower: 0, upper: 50000000 },
  rangeYear: { lower: 1950, upper: new Date().getFullYear() },
  orderBy: null,
  categoryId: null,
  transmission: null,
  seats: null,
  fuel: null,
  position: {
    latitude: null,
    longitude: null,
  },
  address: {
    province: null,
    district: null,
  },
  date: {
    startDate: null,
    endDate: null,
  },
};

export const filterAtom = atom<FilterProps>({
  key: "FILTER",
  default: initialFilter,
  effects_UNSTABLE,
});
