import { useCar } from "atom/car/useCar";
import { useCategory } from "atom/category/useCategory";
import { useFilter } from "atom/filter/useFilter";
import { usePlace } from "atom/place/usePlace";
import Radio from "components/Radio";
import {
  filterFuel,
  filterPrice,
  filterSeats,
  filterTransmission,
} from "data/search";
import Slider from "rc-slider";
import { formatCurrency } from "utils/formatCurrency";

import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface ISidebar {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFilter: React.Dispatch<React.SetStateAction<boolean>>;
  setCars: React.Dispatch<React.SetStateAction<any[]>>;
  tabsSelected: string;
  setTabsSelected: React.Dispatch<React.SetStateAction<string>>;
}

const SearchSidebar: React.FC<ISidebar> = ({
  setCars,
  tabsSelected,
  setTabsSelected,
  setIsFilter,
}) => {
  const { filter, setFilter, resetFilter, getQuery, requestFilterCar } =
    useFilter();
  const { requestGetCarsByPage } = useCar();
  const { serviceCategory } = useCategory();
  const { place } = usePlace();

  const handleResetFilter = async () => {
    resetFilter();
    setTabsSelected("all");
    const res: any = await requestGetCarsByPage.runAsync();
    setCars(res?.data);
    setIsFilter(false);
  };

  const handleFilter = async () => {
    setTabsSelected("all");
    setIsFilter(true);
    try {
      const query = getQuery({
        rangePrice: filter.rangePrice,
        rangeYear: filter.rangeYear,
        orderBy: filter.orderBy,
        categoryId: filter.categoryId,
        transmission: filter.transmission,
        seats: filter.seats,
        fuel: filter.fuel,
        address: filter.address,
      });

      await requestFilterCar
        .runAsync(query)
        .then((res: any) => setCars(res.data));
    } catch (error) {
      console.error("Failed to fetch cars:", error);
    }
  };

  return (
    <div
      key={JSON.stringify(filter)}
      className=" w-full flex flex-col items-start text-start sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8"
    >
      <div className="w-full">
        <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
          Địa điểm
        </span>
        <div className="w-full">
          <Listbox
            value={filter?.address?.province?.id}
            onChange={(e) =>
              setFilter((prev) => ({
                ...prev,
                address: {
                  province: JSON.parse(e.toString()),
                },
              }))
            }
          >
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-lg  py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-colorPrimary sm:text-sm">
                <span className="block truncate">
                  {filter?.address?.province?.title || "Chọn địa điểm"}
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-colorPrimary text-colorPrimary dark:text-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                  {place.map((data, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                          active
                            ? "bg-amber-100 text-colorSecondary/50"
                            : "text-text-white"
                        }`
                      }
                      value={JSON.stringify({
                        id: data?.id,
                        title: data?.title,
                      })}
                    >
                      {({ selected }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {data?.title}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </Listbox>
        </div>
      </div>
      <div className="w-full">
        <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
          Khoảng giá
        </span>
        <div className="relative flex flex-col p-3 space-y-3">
          <Slider
            range
            min={0}
            step={100000}
            max={50000000}
            defaultValue={[filter.rangePrice.lower, filter.rangePrice.upper]}
            allowCross={false}
            onChange={(e) =>
              setFilter((prev) => ({
                ...prev,
                rangePrice: {
                  lower: e[0],
                  upper: e[1],
                },
              }))
            }
          />

          <div className="flex justify-between space-x-5">
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                name="minPrice"
                disabled
                id="minPrice"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-center sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                value={formatCurrency(filter.rangePrice.lower)}
              />
            </div>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                disabled
                name="maxPrice"
                id="maxPrice"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-center sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                value={formatCurrency(filter.rangePrice.upper)}
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="text-[#E1E1E2] mt-3 w-full" />
      <div className="w-full">
        <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
          Năm sản xuất
        </span>
        <div className="relative flex flex-col p-3 space-y-3">
          <Slider
            range
            min={1945}
            max={new Date().getFullYear()}
            defaultValue={[filter.rangeYear.lower, filter.rangeYear.upper]}
            allowCross={false}
            onChange={(e) =>
              setFilter((prev) => ({
                ...prev,
                rangeYear: {
                  lower: e[0],
                  upper: e[1],
                },
              }))
            }
          />

          <div className="flex justify-between space-x-5">
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                name="minYear"
                disabled
                id="minYear"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full  text-center sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                value={filter.rangeYear.lower}
              />
            </div>
            <div className="mt-1 relative rounded-md">
              <input
                type="text"
                disabled
                name="maxYear"
                id="maxYear"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full text-center sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                value={filter.rangeYear.upper}
              />
            </div>
          </div>
        </div>

        <hr className="text-[#E1E1E2] mt-3 w-full" />
      </div>

      <div>
        <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
          Sắp xếp theo
        </span>
        <div className="mt-[15px] flex flex-row items-center  w-full gap-5 flex-wrap">
          {filterPrice.map((item, index) => (
            <Radio
              key={index}
              id={item.orderBy}
              name={"filterPrice"}
              label={item.title}
              checked={filter.orderBy === item.orderBy}
              onChange={(e) =>
                setFilter((prev) => ({
                  ...prev,
                  orderBy: e,
                }))
              }
            />
          ))}
        </div>
      </div>
      <hr className="text-[#E1E1E2] mt-3 w-full" />

      <div>
        <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
          Hãng xe
        </span>
        <div className="mt-[15px] flex flex-row items-center w-full gap-5 flex-wrap">
          {serviceCategory?.map((item, index) => (
            <Radio
              key={index}
              id={item?.xid}
              checked={filter.categoryId === item?.xid}
              label={item?.name}
              name={"serviceCategory"}
              // image={item?.image_url}
              onChange={(e) =>
                setFilter((prev) => ({
                  ...prev,
                  categoryId: e,
                }))
              }
            />
          ))}
        </div>
      </div>
      <hr className="text-[#E1E1E2] mt-3 w-full" />

      <div>
        <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
          Truyền động
        </span>
        <div className="mt-[15px] flex flex-row items-center  w-full gap-5 flex-wrap">
          {filterTransmission.map((item, index) => (
            <Radio
              key={index}
              id={item.value}
              name={"filterTransmission"}
              label={item.title}
              checked={filter.transmission === item.value}
              onChange={(e) =>
                setFilter((prev) => ({
                  ...prev,
                  transmission: e,
                }))
              }
            />
          ))}
        </div>
      </div>
      <hr className="text-[#E1E1E2] mt-3 w-full" />

      <div>
        <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
          Loại nhiên liệu
        </span>
        <div className="mt-[15px] flex flex-row items-center  w-full gap-5 flex-wrap">
          {filterFuel.map((item, index) => (
            <Radio
              key={index}
              id={item.value}
              name={"filterFuel"}
              label={item.title}
              checked={filter.fuel === item.value}
              onChange={(e) =>
                setFilter((prev) => ({
                  ...prev,
                  fuel: e,
                }))
              }
            />
          ))}
        </div>
      </div>
      <div>
        <span className="font-semibold text-lg text-textPrimary dark:text-neutral-200">
          Chỗ ngồi
        </span>
        <div className="mt-[15px] flex flex-row items-center  w-full gap-5 flex-wrap">
          {filterSeats.map((item, index) => (
            <Radio
              key={index}
              id={item.value}
              label={item.title}
              checked={filter.seats === item.value}
              name={"filterSeats"}
              onChange={(e) =>
                setFilter((prev) => ({
                  ...prev,
                  seats: e,
                }))
              }
            />
          ))}
        </div>
      </div>

      <ButtonPrimary
        onClick={handleFilter}
        className="text-white bg-colorPrimary w-full p-3 rounded-full hover:bg-colorPrimary/85 flex flex-row items-center space-x-2 justify-center"
        loading={requestFilterCar.loading}
      >
        Lọc
      </ButtonPrimary>
      <button
        onClick={handleResetFilter}
        className="bark:text-white border border-colorPrimary w-full p-3 rounded-full hover:bg-colorPrimary/15"
      >
        Xóa tất cả
      </button>
    </div>
  );
};

export default SearchSidebar;
