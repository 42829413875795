import Logo from "shared/Logo/Logo";
import { CustomLink } from "data/types";
import React from "react";
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/solid";
import logo from "images/LogoDoc.png";
import SocialsList from "shared/SocialsList/SocialsList";
import { useLocation } from "react-router-dom";
import { routeHiddenLayout } from "data";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Chính sách",
    menus: [
      { href: "/about-us", label: "Về chúng tôi" },
      { href: "/policies-and-regulations", label: "Chính sách và Quy định" },
      { href: "/insurance-policy", label: "Chính sách bảo hiểm" },
      { href: "/terms-of-service", label: "Điều khoản sử dụng" },
      {
        href: "https://ontracking.com.vn/quy-trinh-giai-quyet-khieu-nai",
        label: "Cơ chế giải quyết khiếu nại, tranh chấp",
      },
      { href: "/operational-regulations", label: "Quy chế hoạt động" },
      { href: "/information-security", label: "Bảo mật thông tin" },
    ],
  },
  {
    id: "1",
    title: "Đối tác",
    menus: [
      { href: "/partner-parking", label: "Đối tác bãi xe" },
      { href: "/register-safebox", label: "Đăng ký lắp đặt Safebox" },
    ],
  },
  {
    id: "2",
    title: "Tìm hiểu thêm",
    menus: [
      { href: "/general-guide", label: "Hướng dẫn chung" },
      { href: "/car-booking-guide", label: "Hướng dẫn đặt xe" },
      { href: "/guide-for-car-owners", label: "Hướng dẫn dành cho chủ xe" },
      { href: "/payment-guide", label: "Hướng dẫn thanh toán" },
      { href: "/faq", label: "Câu hỏi thường gặp" },
      { href: "/customer-reviews", label: "Cảm nhận khách hàng" },
      // { href: "/news", label: "Tin tức" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-normal text-textPrimary text-[22px] dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-2 md:space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-[#868A8D] font-normal hover:text-black dark:hover:text-white dark:text-neutral-400"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const { pathname } = useLocation();
  const currentPath = new Set(pathname?.split("/"));
  if (routeHiddenLayout.some((r) => currentPath.has(r))) return;

  return (
    <div className="nc-Footer relative py-12 md:mb-0 md:pt-24 lg:pt-28 ">
      <div className="container grid grid-cols-2 gap-y-5 md:gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-3 lg:grid-cols-5 lg:gap-x-8 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-2 lg:flex lg:flex-col">
          <div className="col-span-4 md:col-span-1">
            <Logo img={logo} className="h-20" />
          </div>
          <div className="space-y-3 md:space-y-5 col-span-4">
            <span className="text-textPrimary text-[22px] font-normal dark:text-neutral-200">
              THÔNG TIN CÔNG TY
            </span>

            <ul className="space-y-3 flex justify-start flex-col">
              <li className="flex flex-row items-center gap-1 space-x-1">
                <PhoneIcon className="h-6 w-6 text-[#868A8D] dark:text-neutral-400" />
                <span className="font-normal text-textPrimary dark:text-neutral-400">
                  Hotline: 0938266958 (ZALO)
                </span>
              </li>
              <li className="flex flex-row items-center gap-1 space-x-1">
                <EnvelopeIcon className="h-6 w-6 text-[#868A8D] dark:text-neutral-400" />
                <span className="font-normal text-textPrimary dark:text-neutral-400">
                  Email: info@ontracking.com.vn
                </span>
              </li>
              <li className="flex flex-row items-start gap-1 space-x-1">
                <MapPinIcon className="h-8 w-7 text-[#868A8D] dark:text-neutral-400" />
                <span className="font-normal text-textPrimary dark:text-neutral-400">
                  68 Nguyễn Huệ, Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh,
                  Việt Nam
                </span>
              </li>
            </ul>
          </div>
          <div className="col-span-4 flex items-center md:col-span-3">
            <SocialsList className="flex items-center space-x-3 lg:flex-row lg:space-x-5" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>

      <hr className="my-5" />
      <div className="flex justify-center items-center">
        <span className="text-textSecondary text-xl font-normal text-center">
          Copyright © 3GO | Powered by{" "}
          <a
            href="https://digibird.co/"
            target="_blank"
            className="text-yellow-500"
            rel="noreferrer"
          >
            Digibird
          </a>
        </span>
      </div>
    </div>
  );
};

export default Footer;
