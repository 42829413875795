import { useRequest } from "ahooks";
import { useRecoilState } from "recoil";
import { FilterProps, filterAtom, initialFilter } from "./filter";
import { useWarehouse } from "atom/warehouse/useWarehouse";
import { getCarFiltersApi } from "services/api/carApi";
import moment from "moment";

export const useFilter = () => {
  const [filter, setFilter] = useRecoilState<FilterProps>(filterAtom);
  const { warehouseId } = useWarehouse();

  const getQuery = ({
    keyword = filter.keyword,
    rangePrice = filter.rangePrice,
    rangeYear = filter.rangeYear,
    orderBy = filter.orderBy,
    categoryId = filter.categoryId,
    transmission = filter.transmission,
    seats = filter.seats,
    fuel = filter.fuel,
    position = filter.position,
    address = filter.address,
    date = filter.date,
  }: FilterProps) => {
    const filterValue = keyword
      ? "&filters=" + encodeURIComponent(`title lk "%${keyword}%"`).trim()
      : "";
    const filterRange = `&price_from=${rangePrice?.lower}&price_to=${rangePrice?.upper}`;
    const filterRangeYear = `&min_year=${rangeYear?.lower}&max_year=${rangeYear?.upper}`;
    const filterOrderBy = orderBy ? `&order=sales_price%20${orderBy}` : "";
    const filterCategory = categoryId ? `&category_id=${categoryId}` : "";
    const filterTransmission = transmission
      ? `&transmission=${transmission}`
      : "";
    const filterFuel = fuel ? `&fuel_type=${fuel}` : "";
    const filterSeats = seats ? `&seats=${seats}` : "";
    const filterPosition =
      position?.latitude && position.longitude
        ? `&latitude=${position.latitude}&longitude=${position.longitude}`
        : "";

    const filterDate =
      date?.startDate && date?.endDate
        ? `&start_date=${moment(date.startDate).format(
            "YYYY-MM-DD"
          )}&end_date=${moment(date.endDate).format("YYYY-MM-DD")}`
        : "";
    const filterAddress = address?.district
      ? `&place_id=${address.district.id}&place_type=city`
      : address?.province
      ? `&place_id=${address.province.id}&place_type=province`
      : "";
    const query =
      filterOrderBy +
      filterRange +
      filterCategory +
      filterTransmission +
      filterSeats +
      filterFuel +
      filterRangeYear +
      filterPosition +
      filterValue +
      filterDate +
      filterAddress;
    return query;
  };
  const requestFilterCar = useRequest(
    (query) => getCarFiltersApi(warehouseId as string, query),
    {
      debounceWait: 250,
      cacheKey: "filter-car",
      manual: true,
    }
  );

  const resetFilter = () => {
    setFilter(initialFilter);
  };

  return { requestFilterCar, filter, setFilter, getQuery, resetFilter };
};
