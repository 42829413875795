import { FC, memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { CarProps } from "atom/car/car";
import LazyImage from "shared/LazyImage/LazyImage";
import { getPrice } from "helpers/utils";
import { formatCurrency } from "utils/formatCurrency";
import RenderFeature from "components/RenderFeature";
import { useWishlist } from "atom/wishlist/useWishlist";

export interface CarCardHProps {
  className?: string;
  car?: CarProps;
}

const CarCardH: FC<CarCardHProps> = ({ className = "", car }) => {
  const navigate = useNavigate();
  const { currentPrice, discountValue, originalPrice } = getPrice(car);
  const { isWishlist, toggleWishlist } = useWishlist();

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full flex items-center justify-center md:w-72 flex-shrink-0 border-r border-neutral-100 dark:border-neutral-800 rounded-md overflow-hidden ">
        <LazyImage
          className="object-cover w-full h-full rounded-md hover:scale-105 transition ease-in-out delay-75 hover:transition-all"
          // style={{ width: "100%", height: "100%" }}
          src={car?.image_url?.split(",")[0]}
        />
        <BtnLikeIcon
          colorClass={` bg-neutral-100 dark:bg-neutral-800 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
          isLiked={isWishlist(car?.xid)}
          className="absolute right-5 top-5 sm:right-3 sm:top-3 z-10"
          onClick={() => toggleWishlist(car)}
        />
        {car?.promotion?.[0]?.name && (
          <SaleOffBadge
            className="absolute left-3 top-3"
            desc={car?.promotion?.[0]?.name}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div
        className="flex-grow p-3 sm:p-5 flex flex-col cursor-pointer"
        onClick={() =>
          navigate("/car-detail/" + car?.slug, {
            state: car,
          })
        }
      >
        <div className="space-y-2">
          <RenderFeature features={car?.car?.features?.split(",")} />

          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <div className="text-xl font-semibold capitalize">
              <span className="line-clamp-1">{car?.title}</span>
            </div>
          </div>
          <div className="flex items-center space-x-4 text-sm text-neutral-500 dark:text-neutral-400">
            {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
            <span>· </span>
            <div className="flex items-center">
              <span className="hidden sm:inline-block  text-base">
                <i className="las la-map-marked"></i>
              </span>
              <span className="sm:ml-2 line-clamp-1"> {car?.car?.address}</span>
            </div>
          </div>
        </div>
        <div className="hidden sm:block w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        {/* SHOW MOBILE */}
        <div className="flex sm:hidden items-center text-sm text-neutral-500 dark:text-neutral-400 space-x-2 mt-4 sm:mt-0">
          <span>{car?.car?.seats} chỗ</span>
          <span>· </span>
          <span>
            {car?.car?.transmission === "automatic" ? "Số tự động" : "Số sàn"}
          </span>
          <span>· </span>
          <span>{car?.car?.color}</span>
        </div>
        {/* SHOW DESK */}
        <div className="hidden sm:flex items-center space-x-8">
          {/* --- */}
          <div className="flex items-center space-x-2">
            <i className="las la-user-friends text-xl"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {car?.car?.seats} Chỗ
            </span>
          </div>
          {/* --- */}
          <div className="flex items-center space-x-2">
            <i className="las la-dharmachakra text-xl"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {car?.car?.transmission === "automatic" ? "Số tự động" : "Số sàn"}
            </span>
          </div>
          {/* --- */}
          <div className="flex items-center space-x-2">
            <i className="las la-fill text-xl"></i>
            <span className="text-sm text-neutral-500 dark:text-neutral-400">
              {car?.car?.color}
            </span>
          </div>
        </div>

        {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 my-4"></div>
        <div className="flex justify-between items-end">
          <div className="flex items-center space-x-3 text-sm text-neutral-700  dark:text-neutral-300">
            <Avatar imgUrl={author.avatar} userName={author.displayName} />
            <span className="hidden sm:inline-block">
              <span className="hidden sm:inline">Car owner </span>{" "}
              {author.displayName}
            </span>
          </div>
          <span className="text-lg font-semibold text-secondary-700">
            {price}
            <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              /day
            </span>
          </span>
        </div> */}

        {/* price */}
        <div className="mt-3 space-x-3">
          <span className="text-gray-400 line-through">
            {formatCurrency(originalPrice)}
          </span>
          <span className="text-2xl font-bold text-colorPrimary">
            {formatCurrency(currentPrice)}
            <span className="text-gray-400 text-lg font-normal">/ngày</span>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className}`}
      data-nc-id="CarCardH"
    >
      <div className="flex flex-col md:flex-row">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default memo(CarCardH);
