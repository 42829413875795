export const filterSeats = [
  {
    title: "5 chỗ",
    value: "5",
  },
  {
    title: "7 chỗ",
    value: "7",
  },
];

export const filterPrice = [
  {
    title: "Giá (thấp đến cao)",
    orderBy: "asc",
  },
  {
    title: "Giá (cao đến thấp)",
    orderBy: "desc",
  },
];
export const filterTransmission = [
  {
    title: "Số tự động",
    value: "automatic",
  },
  {
    title: "Số sàn",
    value: "manual",
  },
];

export const filterFuel = [
  {
    title: "Xăng",
    value: "gasoline",
  },
  {
    title: "Dầu Diesel",
    value: "diesel",
  },
  {
    title: "Điện",
    value: "electric",
  },
];

export const categories = [
  {
    key: "all",
    label: "Tất cả",
  },
  {
    key: "near",
    label: "Xe gần đây",
  },
  {
    key: "flashsale",
    label: "Đang ưu đãi",
  },
  {
    key: "newmanufacture",
    label: "Xe đời mới",
  },
];
