import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import facebookIcon from "images/icons/facebookIcon.svg";
import twitterIcon from "images/icons/twitterIcon.svg";
import linkedInIcon from "images/icons/linkedInIcon.svg";
import instagramIcon from "images/icons/InstagramIcon.svg";
import registered from "images/registered.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  { name: "Facebook", icon: facebookIcon, href: "#" },
  { name: "Twitter", icon: twitterIcon, href: "#" },
  { name: "Youtube", icon: linkedInIcon, href: "#" },
  { name: "Instagram", icon: instagramIcon, href: "#" },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-5 text-2xl ${className}`}
      data-nc-id="SocialsList"
    >
      <FontAwesomeIcon icon={faFacebookF} className="w-6 h-6 cursor-pointer" />
      <FontAwesomeIcon icon={faTwitter} className="w-6 h-6 cursor-pointer" />
      <FontAwesomeIcon icon={faYoutube} className="w-6 h-6 cursor-pointer" />
      <FontAwesomeIcon icon={faInstagram} className="w-6 h-6 cursor-pointer" />
      <img alt="icon" className="w-[120px] md:w-full" src={registered}></img>
    </nav>
  );
};

export default SocialsList;
