import React, { FC } from "react";
import { Helmet } from "react-helmet";

import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";

import SectionDowloadApp from "containers/PageHome/SectionDowloadApp";
import SectionGridFilterCard from "./SectionGridFilterCard";
import SectionOurFeaturesWithList from "./SectionOurFeaturesWithList";
import HomeDadvite from "./HomeDadvite";

import { useNews } from "atom/news/useNews";
import heroRightImage from "images/hero-right-car.png";
import { usePlace } from "atom/place/usePlace";
import { useBanner } from "atom/banner/useBanner";

export interface ListingCarPageProps {
  className?: string;
}

const ListingCarPage: FC<ListingCarPageProps> = ({ className = "" }) => {
  const { news, requestGetNews } = useNews();
  const { place, requestGetHotPlaces } = usePlace();
  const { banner, requestGetBanner } = useBanner();

  return (
    <div
      className={`nc-ListingCarPage relative overflow-hidden ${className}`}
      data-nc-id="ListingCarPage"
    >
      <Helmet>
        <title>3GO – GOSAFE – GOSAVE – GOSMART</title>
        <meta
          name="description"
          content="Thoải mái vi vu khắp nơi với nhiều mẫu xe mới mỗi ngày. 3GO – trung gian kết nối, đáp ứng các nhu cầu, hiện thực hóa mong muốn của chủ sở hữu xe và người thuê xe"
        />
        <meta
          name="keywords"
          content="cho thuê xe, thuê xe hơi, thuê xe máy, 3GO"
        />

        <meta property="og:title" content="3GO – GOSAFE – GOSAVE – GOSMART" />
        <meta
          property="og:description"
          content="Thoải mái vi vu khắp nơi với nhiều mẫu xe mới mỗi ngày. 3GO – trung gian kết nối, đáp ứng các nhu cầu, hiện thực hóa mong muốn của chủ sở hữu xe và người thuê xe"
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta property="og:url" content="https://3go.ui.aimini.vn" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="3GO - Dịch Vụ Cho Thuê Xe Tốt Nhất"
        />
        <meta
          name="twitter:description"
          content="Thoải mái vi vu khắp nơi với nhiều mẫu xe mới mỗi ngày. 3GO – trung gian kết nối, đáp ứng các nhu cầu, hiện thực hóa mong muốn của chủ sở hữu xe và người thuê xe"
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "3GO",
              url: "https://3go.ui.aimini.vn",
              logo: "https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png",
              sameAs: [
                "https://www.facebook.com/3go",
                "https://twitter.com/3go",
              ],
            }),
          }}
        />
      </Helmet>

      <SectionHeroArchivePage
        rightImage={heroRightImage}
        currentPage="Cars"
        currentTab="Tự lái"
        listingType={
          <>
            <i className="text-2xl las la-car"></i>
            <span className="ml-2.5">1512 cars</span>
          </>
        }
        // className="pt-10 pb-24 lg:pb-28"
      />

      {/* SECTION 1 */}
      <SectionOurFeatures />

      <div className="container relative">
        <div className="relative py-16">
          {/* <BackgroundSection /> */}
          <SectionSliderNewCategories
            heading="Khuyến mãi hiện có"
            subHeading="Nhận nhiều ưu đãi hấp dẫn từ 3GO"
            categoryCardType="cardBanner"
            itemPerRow={4}
            sliderStyle="style2"
            uniqueClassName="ListingCarPage"
            data={banner?.data}
            itemClassName="max-w-[354px] max-h-[354px] overflow-hidden rounded-xl mx-auto"
            autoplay={3000}
            loading={requestGetBanner.loading || !banner?.data?.length}
          />
        </div>

        {/* SECTION */}
        <SectionGridFilterCard className="pb-24 lg:pb-28" />

        {/* SECTION */}
        {/* <SectionSubscribe2 className="py-24 lg:py-28" /> */}
      </div>

      <div className="relative py-16 bg-[#F5F5F5] dark:bg-[#111824]">
        {/* <BackgroundSection /> */}
        {/* <BgGlassmorphism /> */}

        <div className="container w-full">
          <SectionSliderNewCategories
            heading="Địa điểm nổi bật"
            subHeading="Khám phá địa điểm thuê xe nổi bật của 3GO"
            categoryCardType="card4"
            itemPerRow={4}
            data={place}
            className="cursor-pointer"
            sliderStyle="style2"
            uniqueClassName="ListingCarPage"
            itemClassName="h-[500px] object-cover"
            isShowContent
            autoplay={3000}
            loading={requestGetHotPlaces?.loading}
            canClicked={true}
          />
        </div>
      </div>

      <SectionOurFeaturesWithList />

      <div className="relative container py-16">
        {/* <BackgroundSection /> */}
        {/* <BgGlassmorphism /> */}

        <SectionSliderNewCategories
          heading="Tin tức"
          // subHeading="Khám phá địa điểm thuê xe nổi bật của 3GO"
          categoryCardType="cardNews"
          itemPerRow={2}
          data={news.data}
          sliderStyle="style2"
          uniqueClassName="ListingCarPage"
          isShowContent
          autoplay={3000}
          loading={requestGetNews.loading || !news.data?.length}
        />
      </div>

      <HomeDadvite />

      <SectionDowloadApp />
    </div>
  );
};

export default ListingCarPage;
