import React, { FC } from "react";
import { ArrowRightIcon, CalendarDaysIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import NcImage from "shared/NcImage/NcImage";

export interface CardNewsProps {
  className?: string;
  data: any;
}

const CardNews: FC<CardNewsProps> = ({ className = "", data }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`nc-CardNews flex flex-col border bottom-1 border-[#F3F4F6] rounded-xl z-10 ${className}`}
      data-nc-id="CardNews"
    >
      <NcImage
        src={data?.image_url}
        className="object-cover w-full h-[228px] rounded-t-xl"
      />

      <div className="px-5 py-7 space-y-5">
        <div className="flex flex-row items-center gap-1">
          <CalendarDaysIcon className="w-5 h-5 text-[#8C8C8C]" />
          <span className="font-semibold text-[#868A8D]">
            {moment(data?.created_at).format("DD/MM/YYYY")}
          </span>
        </div>

        <div className="flex flex-col space-y-1">
          <h2
            className={`sm:text-lg text-textPrimary font-normal truncate lg:text-2xl dark:text-neutral-200`}
          >
            {data?.title}
          </h2>
          <span className="text-lg font-normal text-[#868A8D] line-clamp-3">
            <div dangerouslySetInnerHTML={{ __html: data?.summary }} />
          </span>
        </div>

        <div className="flex flex-row items-center gap-2 text-colorPrimary">
          <span
            className="font-normal cursor-pointer"
            onClick={() =>
              navigate(`news/${data?.slug}`, {
                state: data,
              })
            }
          >
            Xem thêm
          </span>
          <ArrowRightIcon className="w-3" />
        </div>
      </div>
    </div>
  );
};

export default CardNews;
