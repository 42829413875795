import React from "react";
import { getItemFromStore, ITEM_STORE_KEY } from "helpers/utils";
import LoadingWithLogo from "components/LoadingWithLogo";
import { useAsyncEffect } from "ahooks";
import { useUser } from "atom/user/useUser";
import { useNavigate } from "react-router-dom";
import { removeDataFromStorage } from "services/storage";
import { showCustomToast } from "shared/toast";

const PageZaloLogin = () => {
  const { requestLoginZalo } = useUser();
  const navigate = useNavigate();

  const code_verifier = getItemFromStore(
    ITEM_STORE_KEY.CODE_VERIFY,
    sessionStorage
  );

  useAsyncEffect(async () => {
    if (!code_verifier) return navigate("/login");

    let data = new URLSearchParams({
      app_id: process.env.REACT_APP_ID,
      code: new URLSearchParams(window.location.search).get("code"),
      code_verifier,
      grant_type: "authorization_code",
    }).toString();

    await new Promise((resolve) => setTimeout(resolve, 500));

    await requestLoginZalo(data, navigate)
      .then(() => {})
      .catch(() => {
        showCustomToast({ type: 2, message: "Đăng nhập thất bại" });
        navigate(-1);
      })
      .finally(() => {
        removeDataFromStorage(ITEM_STORE_KEY.CODE_VERIFY, sessionStorage);
      });
  }, [window.location]);

  return (
    <div className="w-full flex items-center justify-center min-h-[300px]">
      <LoadingWithLogo />
    </div>
  );
};

export default PageZaloLogin;
