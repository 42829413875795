import React from "react";
import Badge from "shared/Badge/Badge";
import { Swiper, SwiperSlide } from "swiper/react";

const RenderFeature = ({ features }) => {
  if (!Array.isArray(features) || features.length === 0) {
    return <div className="h-7" />;
  }

  return (
    <Swiper
      className="flex gap-3 space-x-1 no-scrollbar"
      slidesPerView={"auto"}
      spaceBetween={10}
      loop={false}
    >
      {features?.map((item, index) => (
        <SwiperSlide className="!w-fit" key={index}>
          <Badge
            className=""
            key={index}
            name={
              <span className="text-colorPrimary text-xs font-normal">
                {item}
              </span>
            }
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default RenderFeature;
