import { generateCodeChallenge } from "utils";
import { ApiUtils } from "./index";
import { PATH } from "./path";
import { ITEM_STORE_KEY, setItemToStore } from "helpers/utils";
import axios from "axios";

export const signUpWebApi = async (data: any) => {
  return await ApiUtils.post(PATH.sign_up_web, data);
};

export const signInWebApi = async (data: any) => {
  return await ApiUtils.post(PATH.sign_in_web, data);
};

export const signUpZaloApi = async (data: any) => {
  return await ApiUtils.post(PATH.sign_up_zalo, data);
};

export const updateInfoUserApi = async (data, token) => {
  return await ApiUtils.post(PATH.update_user_zalo, data, { token: token });
};

export const getProfileApi = async (token) => {
  return await ApiUtils.request(PATH.get_profile, { token: token });
};

export const refreshTokenApi = async (token) => {
  return await ApiUtils.post(PATH.refresh_token, { token: token });
};

export const forgotPasswordApi = async (token, data) => {
  return await ApiUtils.post(PATH.forgot_password, data, { token: token });
};

export const getOtpApi = async (phone) => {
  return await ApiUtils.post(PATH.get_otp, {
    phone,
    type: "zalo",
  });
};

export const sendOtpApi = async (data) => {
  return await ApiUtils.post(PATH.send_otp, data);
};

export const permissionZalo = async () => {
  const codes = generateCodeChallenge();
  const url =
    process.env.REACT_APP_ZALO_PERMISSION_URL +
    "?" +
    new URLSearchParams({
      app_id: process.env.REACT_APP_ID,
      code_challenge: codes.challenge,
      redirect_uri: process.env.REACT_APP_CALLBACK_URL,
      state: Math.random().toString(36).substr(2, 9),
    });

  setItemToStore(ITEM_STORE_KEY.CODE_VERIFY, codes.verifier, sessionStorage);

  // window.location.replace(url);
  const width = 500;
  const height = 600;
  const left = window.screen.width / 2 - width / 2;
  const top = window.screen.height / 2 - height / 2;

  window.open(
    url,
    "ZaloLogin",
    `width=${width},height=${height},top=${top},left=${left},status=no,toolbar=no,menubar=no,location=no`
  );
};

export const loginWithZalo = async (data) => {
  return await ApiUtils.post(
    process.env.REACT_APP_ZALO_ACCESS_TOKEN_URL,
    data,
    {
      secret_key: process.env.REACT_APP_SECRET_KEY,
      contentType: "x-www-form-urlencoded",
    }
  );
};

export const zaloLogout = async (token) => {
  // return await axios.post(
  //   `https://id.zalo.me/account/logout?continue=https://3go.ui.aimini.vn/login`
  // );

  await fetch(
    "ttps://id.zalo.me/account/logout?continue=https://3go.ui.aimini.vn",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
