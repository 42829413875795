import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { useCar } from "atom/car/useCar";

import { useUser } from "atom/user/useUser";
import DetailPagetLayout from "containers/ListingDetailPage/Layout";
import ModalReport from "containers/ListingDetailPage/listing-car-detail/ModalReport";
import ModalPolicyCancel from "containers/ListingDetailPage/listing-car-detail/ModalPolicyCancel";
import Page404 from "containers/Page404/Page404";
import ListingCarDetail from "./ListingCarDetail";
import ModalCancel from "components/ModalCancel";
import FooterCurrentRental from "./FooterCurrentRental";
import { useBooking } from "atom/booking/useBooking";
import { showCustomToast } from "shared/toast";

export const CurrentRentalDetailPage: React.FC = () => {
  const { requestGetCar } = useCar();
  const { user } = useUser();
  const { booking, requestMyBookings } = useBooking();

  const params = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [currentData, setCurrentData] = useState(null);
  const [modal, setModal] = useState<{
    report: boolean;
    cancel: boolean;
    active: boolean;
    PolicyCancel: {
      type: 1 | 2;
      isShow: boolean;
    };
  }>({
    report: false,
    cancel: false,
    active: false,
    PolicyCancel: {
      type: 1,
      isShow: false,
    },
  });
  const [dateSelected, setDateSelected] = useState<{
    startDate: Date;
    endDate: Date;
    startTime: any;
    endTime: any;
  }>({
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  });
  const [methodPickupCar, setMethodPickupCar] = useState({
    method: "Nhận xe tại vị trí",
    address: currentData?.car?.address,
  });

  useEffect(() => {
    if (state) {
      setCurrentData(state);
    } else {
      const data = booking?.find(
        (item) => item?.items?.[0]?.service?.slug === params.slug
      );
      setCurrentData(data);
    }
  }, [state, params?.slug, booking]);

  const handleCheckout = () => {
    if (!user)
      return showCustomToast({
        type: 3,
        message: '"Vui lòng đăng nhập trước khi thanh toán"',
      });
    if (JSON.stringify(dateSelected)?.includes("null")) {
      showCustomToast({
        type: 3,
        message: "Vui lòng chọn ngày thuê và thời gian nhận xe!",
      });
      return;
    }

    if (
      methodPickupCar?.method !== "Nhận xe tại vị trí" &&
      !methodPickupCar?.address
    )
      return showCustomToast({
        type: 2,
        message: "Vui lòng thêm địa chỉ giao xe",
      });

    const data = { ...currentData, ...dateSelected, methodPickupCar };

    navigate("/checkout", {
      state: data,
    });
  };

  const service = useMemo(() => currentData?.items?.[0], [currentData]);
  if ((!currentData || !user) && !requestMyBookings.loading) return <Page404 />;

  return (
    <DetailPagetLayout>
      <Helmet>
        <title>3GO | Chi tiết xe thuê</title>
        <meta
          name="description"
          content="Khám phá thông tin chi tiết về các loại xe thuê tại 3GO, bao gồm giá cả, tính năng, và điều kiện thuê."
        />
        <meta property="og:title" content="3GO || Chi tiết xe thuê" />
        <meta
          property="og:description"
          content="Khám phá thông tin chi tiết về các loại xe thuê tại 3GO, bao gồm giá cả, tính năng, và điều kiện thuê."
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="3GO || Chi tiết xe thuê" />
        <meta
          name="twitter:description"
          content="Khám phá thông tin chi tiết về các loại xe thuê tại 3GO, bao gồm giá cả, tính năng, và điều kiện thuê."
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Person",
              name: "Người Dùng",
              url: window.location.href,
              image:
                "https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png",
            }),
          }}
        />
      </Helmet>

      <div className="">
        <ListingCarDetail
          handleCheckout={handleCheckout}
          key={currentData?.xid}
          currentData={currentData}
          car={currentData?.items?.[0]?.service?.car}
          requestGetCar={requestGetCar}
          dateSelected={{
            startDate: service?.pickup_date,
            endDate: service?.return_date,
            startTime: service?.pickup_date,
            endTime: service?.return_date,
          }}
          methodPickupCar={methodPickupCar}
          setMethodPickupCar={setMethodPickupCar}
          setModal={setModal}
        />

        {/* <div className={`relative pt-16 ${!carNear?.data && "hidden"}`}>
          <SectionSliderNewCategories
            heading="Xe gần đây"
            categoryCardType="cardCar"
            itemPerRow={4}
            sliderStyle="style2"
            uniqueClassName="ListingCarPage"
            data={carNear?.data || []}
            itemClassName="max-w-[354px] max-h-[354px] overflow-hidden rounded-xl mx-auto"
            autoplay={3000}
            loading={false}
          />
        </div> */}
      </div>

      <ModalReport isOpen={modal} setIsOpen={setModal} />
      <ModalCancel isOpen={modal} setIsOpen={setModal} />
      <ModalPolicyCancel isOpen={modal} setIsOpen={setModal} />

      <FooterCurrentRental setModal={setModal} />
    </DetailPagetLayout>
  );
};

export default CurrentRentalDetailPage;
