import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import { DataProps } from "atom/news/news";
import moment from "moment";

export interface Card13Props {
  className?: string;
  news: DataProps;
}

const CardNewsMedium: FC<Card13Props> = ({ className = "", news }) => {
  return (
    <div
      className={`nc-Card13 relative flex flex-row items-center justify-between md:justify-start ${className}`}
      data-nc-id="Card13"
    >
      <div className="flex flex-col h-full py-2 md:w-2/4">
        <h2 className={`nc-card-title block font-semibold text-base`}>
          <Link
            to={news.slug}
            className="line-clamp-2"
            title={news.title as string}
          >
            {news.title}
          </Link>
        </h2>
        <span className="hidden sm:block my-3 text-neutral-500 dark:text-neutral-400 ">
          <span className="line-clamp-2">
            <div dangerouslySetInnerHTML={{ __html: news.description }} />
          </span>
        </span>
        <span className="mt-4 block sm:hidden text-sm text-neutral-500 ">
          {moment(news.created_at).format("lll")}
        </span>
        <div className="mt-auto hidden sm:block">
          {/* <PostCardMeta meta={{ ...post }} /> */}
        </div>
      </div>

      <Link
        to={news.slug}
        className={`block relative flex-shrink-0 sm:w-1/3 ml-3 sm:ml-5 w-[100px] h-[100px] md:w-[150px] md:h-[150px]`}
      >
        <NcImage
          containerClassName="absolute inset-0 "
          className="object-cover  rounded-xl sm:rounded-3xl"
          src={news.image_url as string}
          alt={news.title as string}
        />
        {/* <PostTypeFeaturedIcon
          className="absolute bottom-2 left-2"
          postType={postType}
          wrapSize="w-8 h-8"
          iconSize="w-4 h-4"
        /> */}
      </Link>
    </div>
  );
};

export default CardNewsMedium;
