import React, { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useUser } from "atom/user/useUser";
import { permissionZalo, signInWebApi } from "services/api/authApi";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { showCustomToast } from "shared/toast";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { ITEM_STORE_KEY, setItemToStore } from "helpers/utils";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { REGEX } from "schema/regex";

import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import zaloSvg from "images/zaloLogo.svg";
import Input from "shared/Input/Input";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    key: "fb",
    name: "Tiếp tục với Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Tiếp tục với Google",
    href: "#",
    icon: googleSvg,
    key: "gg",
  },
  {
    name: "Tiếp tục với Zalo",
    href: "#",
    icon: zaloSvg,
    key: "zalo",
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const { setUser } = useUser();
  const navigate = useNavigate();

  const [isShowPass, setIsShowPass] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    company_id: process.env.REACT_APP_ID_COMPANY,
    username: "",
    password: "",
  });

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const currentData: any = {
      password: data.password,
      company_id: data.company_id,
    };

    if (data.username.match(REGEX.NUMBER_PHONE)) {
      currentData.phone = data.username;
    } else {
      currentData.email = data.username;
    }

    try {
      await signInWebApi(currentData).then((res: any) => {
        setItemToStore(
          ITEM_STORE_KEY.USER_TOKEN,
          res?.data?.user,
          sessionStorage
        );
        setItemToStore(ITEM_STORE_KEY.USER_TOKEN, res?.data?.token);
        setItemToStore(ITEM_STORE_KEY.TOKEN_EXPIRES, res?.data?.expires_in);
        setUser(res?.data);
      });
      navigate("/");
    } catch (error) {
      if (error?.error_message)
        return showCustomToast({ type: 2, message: error?.error_message });
      const err = error?.error?.details;
      if (err)
        return showCustomToast({
          type: 2,
          message: Object.values(err)?.[0]?.[0],
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginWithFacebook = () => {
    console.log("handleLoginWithFacebook");
  };
  const handleLoginWithGoogle = () => {
    console.log("handleLoginWithGoogle");
  };
  const handleLoginWithZalo = async () => {
    await permissionZalo();
  };

  // if (window.opener) {
  //   // Gửi thông điệp về cửa sổ chính
  //   window.opener.postMessage("QR_SCAN_SUCCESS", window.location.origin);

  //   // Đóng cửa sổ popup
  //   window.close();
  // }

  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     // Kiểm tra nguồn gửi thông điệp có phải từ popup không
  //     if (
  //       event.origin === window.location.origin &&
  //       event.data === "QR_SCAN_SUCCESS"
  //     ) {
  //       console.log("QR scan successful!");

  //       // Thực hiện điều hướng hoặc cập nhật giao diện
  //       // Ví dụ: window.location.reload(); hoặc điều hướng trang
  //     }
  //   };

  //   window.addEventListener("message", handleMessage);

  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>3GO | Đăng nhập</title>
        <meta
          name="description"
          content="Đăng nhập vào tài khoản của bạn để tiếp tục sử dụng dịch vụ cho thuê xe của chúng tôi."
        />
        <meta property="og:title" content="3GO || Đăng nhập" />
        <meta
          property="og:description"
          content="Đăng nhập vào tài khoản của bạn để tiếp tục sử dụng dịch vụ cho thuê xe của chúng tôi."
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta property="og:url" content={window.location.href + "/login"} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="3GO || Đăng nhập" />
        <meta
          name="twitter:description"
          content="Đăng nhập vào tài khoản của bạn để tiếp tục sử dụng dịch vụ cho thuê xe của chúng tôi."
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
      </Helmet>

      <BgGlassmorphism />
      <div className="container mb-24 lg:mb-32 z-50">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Đăng nhập
        </h2>
        <div className="max-w-md mx-auto space-y-6 z-50">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <button
                key={index}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                onClick={() => {
                  if (item.key === "fb") handleLoginWithFacebook();
                  if (item.key === "gg") handleLoginWithGoogle();
                  if (item.key === "zalo") handleLoginWithZalo();
                }}
              >
                <img
                  className="flex-shrink-0 w-[20px]"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </button>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-transparent dark:text-neutral-400">
              hoặc
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleLogin}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email / Số điện thoại
              </span>
              <Input
                placeholder="Nhập email hoặc số điện thoại..."
                className="mt-1 placeholder:text-transparent focus:placeholder:text-neutral-400 border border-neutral-300 focus:border-primary-500 transition-all placeholder:duration-300 ease-in-out transform"
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    username: e.target.value,
                  }))
                }
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Mật khẩu
                <Link
                  to="/forgot-pass"
                  className="text-sm text-colorPrimary relative font-semibold"
                >
                  Quên mật khẩu
                </Link>
              </span>
              <Input
                type={isShowPass ? "text" : "password"}
                className="mt-1"
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                icon={
                  <div
                    onClick={() => setIsShowPass(!isShowPass)}
                    className="cursor-pointer"
                  >
                    {isShowPass ? (
                      <EyeIcon className="w-5 h-5" />
                    ) : (
                      <EyeSlashIcon className="w-5 h-5" />
                    )}
                  </div>
                }
              />
            </label>
            <ButtonPrimary
              loading={isLoading}
              type="submit"
              disabled={!data?.password || !data?.username}
            >
              Tiếp tục
            </ButtonPrimary>
          </form>

          <span className="block text-center text-neutral-700 dark:text-neutral-300 z-50 relative">
            Chưa có tài khoản? {` `}
            <Link
              to="/signup"
              className="text-colorPrimary font-semibold cursor-pointer z-50"
            >
              Tạo tài khoản
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
