import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import RenderFeature from "components/RenderFeature";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import LazyImage from "shared/LazyImage/LazyImage";
import { CarProps } from "atom/car/car";
import { useWishlist } from "atom/wishlist/useWishlist";
import { formatCurrency } from "utils/formatCurrency";
import { getPrice } from "helpers/utils";
import { MapPinIcon, StarIcon } from "@heroicons/react/24/outline";
import { SeatsIcon, VoLangIcon } from "images/svg";

export interface CarCardProps {
  className?: string;
  data: CarProps | any;
  size?: "default" | "small";
}

const CarCard: FC<CarCardProps> = ({
  size = "default",
  className = "",
  data,
}) => {
  const { currentPrice, discountValue, originalPrice } = getPrice(data);
  const { isWishlist, toggleWishlist } = useWishlist();

  const navigate = useNavigate();

  const handleViewCar = () => {
    navigate(`/car-detail/${data?.slug}`, {
      state: data,
    });
  };

  if (!data) return;

  // console.log(data);

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full overflow-hidden">
        <div
          className="aspect-w-16 aspect-h-9 cursor-pointer"
          onClick={handleViewCar}
        >
          <LazyImage
            // containerClassName="flex items-center justify-center"
            className="w-full h-full"
            src={data?.image_url?.split(",")[0]}
          />
        </div>
        <BtnLikeIcon
          colorClass={` bg-neutral-100 dark:bg-neutral-800 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
          isLiked={isWishlist(data?.xid)}
          className="absolute right-5 top-5 sm:right-3 sm:top-3 z-10"
          onClick={() => toggleWishlist(data)}
        />
        {data?.promotion?.[0]?.name && (
          <SaleOffBadge
            className="absolute left-3 top-3"
            desc={"-" + data?.promotion?.[0]?.voucher_value + "%"}
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-3" : "p-3  space-y-2"}>
        <div className="space-y-2">
          <RenderFeature features={data?.car?.features?.split(",")} />
          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={`capitalize ${
                size === "default"
                  ? "text-xl font-semibold"
                  : "text-base font-medium"
              }`}
            >
              <span
                className="line-clamp-1 cursor-pointer"
                onClick={handleViewCar}
              >
                {data?.title}
              </span>
            </h2>
          </div>
          <div className="flex justify-between items-center text-textSecondary dark:text-neutral-400 space-x-2">
            <div className="flex items-center">
              <SeatsIcon className="w-5 h-5" />
              <span className="ml-1">{data?.car?.seats}</span>
            </div>
            <div className="flex items-center">
              <VoLangIcon className="w-5 h-5" />
              <span className="ml-1"> Số tự động</span>
            </div>
            <div className="flex items-center">
              <StarIcon className="w-5 h-5" />
              <span className="ml-1">4.5</span>
            </div>
          </div>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>

        <div className="flex items-center">
          <MapPinIcon className="w-5 h-5" />
          <span className="ml-1  line-clamp-1 text-sm text-neutral-500 dark:text-neutral-400">
            {data?.car?.address}
          </span>
        </div>
        <div>
          <span className="text-lg text-textSecondary line-through mr-2">
            {formatCurrency(originalPrice)}
          </span>
          <div className="flex justify-between items-center">
            <span className="text-2xl font-semibold text-colorPrimary">
              {currentPrice && formatCurrency(currentPrice)}
              {size === "default" && (
                <span className="text-xl text-neutral-500 font-normal">
                  /ngày
                </span>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-CarCard group relative border border-neutral-200 dark:border-neutral-700 rounded-xl overflow-hidden hover:shadow-xl transition-shadow bg-white dark:bg-neutral-900 will-change-transform my-1 ${className}`}
      data-nc-id="CarCard"
      // onClick={handleViewCar}
    >
      <div className="flex flex-col">
        {renderSliderGallery()}
        {renderContent()}
      </div>
    </div>
  );
};

export default CarCard;
