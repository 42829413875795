import { useWishlist } from "atom/wishlist/useWishlist";
import React from "react";

const LikeSaveBtns = ({ car }: { car?: any }) => {
  const { isWishlist, toggleWishlist } = useWishlist();
  const handleLike = () => {
    toggleWishlist(car);
  };
  const handleShare = () => {};
  return (
    <div className="flow-root min-w-fit">
      <div className="flex text-[#374151] dark:text-neutral-300 -mx-3 -my-1.5 space-x-1 md:space-x-3">
        <span
          className="flex items-center1 rounded-full px-2.5 py-2  items-center xl:px-4  bg-[#F5F5F5] hover:bg-gray-300 cursor-pointer xl:min-w-[104px] space-x-2 dark:bg-colorPrimary"
          onClick={handleShare}
        >
          <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.4927 6.10212L15.4969 6.10498L15.4978 6.1064C15.4987 6.10811 15.5 6.11189 15.5 6.11728C15.5 6.12268 15.4987 6.12646 15.4978 6.12817L15.4968 6.12968C15.4966 6.12997 15.4957 6.13095 15.4935 6.13245L8.56858 10.8133L8.56809 10.8137C8.56762 10.814 8.56719 10.8143 8.5668 10.8145C8.56654 10.8144 8.56626 10.8142 8.56596 10.8141C8.56292 10.8124 8.5612 10.8104 8.56059 10.8094C8.56036 10.809 8.56011 10.8085 8.55984 10.8076C8.55959 10.8067 8.5589 10.804 8.5589 10.7986V8.90408V8.07947L7.82772 8.46073C6.66532 9.06684 5.21814 10.0956 4.10821 11.7815C3.15615 13.2276 2.46769 15.1341 2.40197 17.6254C1.13561 16.0436 0.412848 14.0024 0.50843 11.804C0.605492 9.57731 1.52545 7.58666 2.95499 6.11958C4.30715 4.73267 6.11326 3.81607 8.10972 3.60877L8.55808 3.56222V3.11145V1.43598C8.55808 1.43057 8.55878 1.42786 8.55901 1.42706C8.55926 1.42617 8.55949 1.42575 8.55967 1.42545C8.56021 1.42458 8.56185 1.42259 8.56492 1.42092C8.56691 1.41984 8.56808 1.41966 8.56813 1.41965C8.56815 1.41965 8.56786 1.41969 8.5672 1.41967C8.56613 1.41964 8.56503 1.41944 8.5643 1.4192C8.56347 1.41894 8.56466 1.41914 8.5679 1.42132C8.56793 1.42134 8.56795 1.42135 8.56797 1.42137L15.4927 6.10212Z"
              stroke="#868A8D"
            />
          </svg>

          <span className="hidden sm:block lg:text-md w-full">Chia sẻ</span>
        </span>
        <span
          className="flex items-center rounded-full p-2 bg-[#F5F5F5] hover:bg-gray-300 cursor-pointer  space-x-2 dark:bg-colorPrimary"
          onClick={handleLike}
        >
          <svg
            width="20"
            viewBox="0 0 20 18"
            fill={isWishlist(car?.xid) ? "currentColor" : "none"}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.31802 2.2377C0.56066 3.99506 0.56066 6.8443 2.31802 8.60166L10.0001 16.2837L17.682 8.60166C19.4393 6.8443 19.4393 3.99506 17.682 2.2377C15.9246 0.480338 13.0754 0.480338 11.318 2.2377L10.0001 3.55577L8.68198 2.2377C6.92462 0.480338 4.07538 0.480338 2.31802 2.2377Z"
              stroke="#868A8D"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="hidden sm:block w-full">Thích</span>
        </span>
      </div>
    </div>
  );
};

export default LikeSaveBtns;
