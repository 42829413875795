import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";

import LikeSaveBtns from "components/LikeSaveBtns";
import StartRating from "components/StartRating/StartRating";
import TextWithLeftBorder from "components/TextWithLeftBorder";
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import ButtonClose from "shared/ButtonClose/ButtonClose";

import {
  ArrowRightIcon,
  InformationCircleIcon,
  Squares2X2Icon,
} from "@heroicons/react/24/outline";
import { CalendarIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { useSetting } from "atom/setting/useSetting";
import { formatCurrency } from "utils/formatCurrency";

import {
  CheckIcon,
  FlatIcon,
  NonCheckIcon,
  SecurityIcon,
  VoLangIcon,
  ForwardIcon,
  LeaseIcon,
  MoneyIcon,
  PenIcon,
  ProductManagerIcon,
  PolicyIcon,
} from "images/svg";
import { SaleIcon } from "images/Sale";
import { useUser } from "atom/user/useUser";
import Characteristic from "containers/ListingDetailPage/listing-car-detail/Characteristic";
import FormPay from "containers/ListingDetailPage/listing-car-detail/FormPay";
import { getPrice } from "helpers/utils";
import moment from "moment";
import classNames from "classnames";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommentListing from "components/CommentListing/CommentListing";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";

const ListingCarDetail: FC<{
  car?: any;
  requestGetCar?: any;
  currentData: any;

  handleCheckout: () => void;
  dateSelected: {
    startDate: Date;
    endDate: Date;
    startTime: any;
    endTime: any;
  };

  methodPickupCar: {
    method: string;
    address: any;
  };
  setMethodPickupCar: React.Dispatch<
    React.SetStateAction<{
      method: string;
      address: any;
    }>
  >;
  setModal: React.Dispatch<
    React.SetStateAction<{
      report: boolean;
      cancel: boolean;
      active: boolean;
      PolicyCancel: {
        type: 1 | 2;
        isShow: boolean;
      };
    }>
  >;
}> = ({
  car,
  requestGetCar,
  currentData,
  handleCheckout,
  dateSelected,
  methodPickupCar,
  setMethodPickupCar,
  setModal,
}) => {
  const descRef = useRef(null);
  const navigate = useNavigate();
  const { setShowImageGalleryModal } = useSetting();
  const {
    user: { user },
  } = useUser();

  const service = currentData?.items?.[0]?.service;
  const { currentPrice } = getPrice(service);

  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [isShowFull, setIsShowFull] = useState({
    desc: false,
    insurance: false,
    secure: false,
    map: false,
  });

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [secureSelected, setSecureSelected] = useState([]);

  const [center, setCenter] = useState({
    lat: car?.latitude || 0,
    lng: car?.longitude || 0,
  });

  useEffect(() => {
    if (car?.data?.length > 0) {
      setCenter({
        lat: car?.latitude,
        lng: car?.longitude,
      });
    }
  }, [car?.data]);

  useEffect(() => {
    if (descRef.current) {
      const isOverflow =
        descRef.current.scrollHeight > descRef.current.clientHeight;
      setIsOverflowing(isOverflow);
    }
  }, [isShowFull?.desc]);

  const images = useMemo(() => {
    if (!service?.image_url) return [];
    return service?.image_url?.split(",");
  }, [service]);

  const handleSelectSecure = (id) => {
    let newSecureSelected = [...secureSelected];
    if (newSecureSelected.includes(id)) {
      newSecureSelected = secureSelected.filter((item) => item !== id);
    } else {
      newSecureSelected.push(id);
    }
    setSecureSelected(newSecureSelected);
  };

  const handleOpenModalImageGallery = () => {
    setShowImageGalleryModal({
      show: true,
      images: service?.image_url?.split(","),
    });
  };

  const handleViewCarDetail = () => {
    navigate(`/car-detail/${service?.slug}`);
  };

  const renderSection1 = () => {
    return (
      <div className="!space-y-6 py-3">
        {requestGetCar?.loading || !service ? (
          <div role="status" className="animate-pulse">
            <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-56"></div>
          </div>
        ) : (
          <h2
            className="text-2xl sm:text-3xl lg:text-4xl font-normal cursor-pointer"
            onClick={handleViewCarDetail}
          >
            {service?.title}
          </h2>
        )}

        <div className="flex flex-row justify-between items-center mt-1">
          <div className="flex items-center space-x-6 flex-wrap">
            <span className="flex flex-row items-center space-x-1">
              <MapPinIcon className="text-[#868A8D] w-5 h-5" />
              {requestGetCar?.loading || !service ? (
                <div role="status" className=" animate-pulse w-auto">
                  <div className="h-4 bg-gray-200 rounded-full mr-3 dark:bg-gray-700 w-64"></div>
                </div>
              ) : (
                <span className="font-normal text-base text-[#868A8D]">
                  {car?.address}
                </span>
              )}
            </span>

            <div className="flex space-x-4 py-3">
              <StartRating />

              <span className="flex flex-row items-center space-x-1">
                <VoLangIcon className="w-5 h-5" />
                <span className="font-normal text-base text-[#868A8D]">
                  50+ chuyến
                </span>
              </span>
            </div>
          </div>
          <LikeSaveBtns car={service} />
        </div>
        <hr className="bg-[#DCE2E8]" />

        <div>
          <TextWithLeftBorder
            title="Đặc điểm"
            className="text-xl font-normal"
          />

          <Characteristic {...service} />
        </div>

        <hr className="bg-[#DCE2E8]" />

        <div className="space-y-4 flex flex-col" ref={descRef}>
          <TextWithLeftBorder title="Mô tả" className="text-xl font-semibold" />
          {requestGetCar?.loading || !service ? (
            <div role="status" className="animate-pulse space-y-2">
              <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
              <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-{90%]"></div>
              <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-[95%]"></div>
              <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-[75%]"></div>
            </div>
          ) : (
            <span
              className={`text-[#868A8D] font-normal line-clamp-3 drop-shadow-2xl  ${
                isShowFull.desc && "h-full line-clamp-none"
              }`}
            >
              <div
                style={{
                  color: "#868A8D",
                }}
                dangerouslySetInnerHTML={{ __html: service?.description }}
              />
            </span>
          )}

          {100 && (
            <button
              className="rounded-full border w-fit mx-auto px-2 py-1 text-colorPrimary text-xs md:text-sm"
              onClick={() =>
                setIsShowFull((prev) => ({
                  ...prev,
                  desc: !prev.desc,
                }))
              }
            >
              {isShowFull.desc ? "Ẩn bớt" : "Xem thêm"}
            </button>
          )}
        </div>

        <hr className="bg-[#DCE2E8]" />

        <TextWithLeftBorder
          title="Tiện nghi"
          className="text-xl font-semibold"
        />
        {requestGetCar?.loading || !service ? (
          <div
            role="status"
            className="animate-pulse flex flex-row justify-between"
          >
            <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-44"></div>
            <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-48"></div>
            <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-52"></div>
            <div className="h-7 bg-gray-200 rounded-full dark:bg-gray-700 w-44"></div>
          </div>
        ) : (
          <div className="mt-5  items-center grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
            {car?.features?.split(",")?.map((item, index) => (
              <div
                key={index}
                className="col flex row-span-4 flex-row items-center  space-x-5 text-textSecondary"
              >
                {/* <img src={item?.image} alt="" className="w-6 h-6" /> */}
                <span className="font-normal">{item}</span>
              </div>
            ))}
          </div>
        )}

        <hr className="bg-[#DCE2E8]" />

        <div>
          <TextWithLeftBorder
            title="Vị trí xe"
            className="text-xl font-semibold mb-5"
          />

          <span className="text-textSecondary font-normal pb-5">
            {car?.adddress}
          </span>

          <div
            className={`xl:flex-grow xl:static xl:block my-3 ${
              isShowFull.map ? "fixed inset-0 z-50" : "h-[300px]"
            }`}
          >
            {isShowFull.map && (
              <ButtonClose
                onClick={() =>
                  setIsShowFull((prev) => ({
                    ...prev,
                    map: false,
                  }))
                }
                className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
              />
            )}

            <div className="relative xl:sticky xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] max-h-[355px] rounded-md overflow-hidden">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={12}
                center={center}
              >
                {[service]?.map((item) => (
                  <AnyReactComponent
                    isSelected={currentHoverID === item?.xid}
                    key={item?.xid}
                    lat={item?.car?.latitude}
                    lng={item?.car?.longitude}
                    car={item}
                  />
                ))}
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    const averageRating =
      currentData?.ratings?.reduce((acc, curr) => acc + curr.rating, 0) /
        currentData?.ratings?.length || 0;

    const textReview = ["Rất kém", "Kém", "Bình thường", "Tốt", "Rất tốt"];
    return (
      <div className="">
        <hr className="bg-[#DCE2E8] mb-3" />

        {/* HEADING */}
        <TextWithLeftBorder
          title="Đánh giá"
          className="text-xl font-semibold mb-5"
        />

        {/* Content */}
        <div className="space-y-5">
          {!currentData?.rating?.length ? (
            <span className="text-2xl text-colorPrimary font-semibold">
              Chưa có đánh giá
            </span>
          ) : (
            <div className="flex flex-row justify-start items-center space-x-10 md:space-x-16">
              <div className="space-y-1">
                <h2 className="text-2xl font-bold text-colorPrimary">
                  {averageRating}
                </h2>
                <span className="text-sm font-semibold text-colorPrimary">
                  {textReview[Math.floor(averageRating) - 1]}
                </span>
              </div>
              <div className="space-y-2 flex flex-col">
                <FiveStartIconForRate
                  iconClass="w-5 h-5"
                  className="space-x-1"
                />
                <span className="text-[#868A8D] text-sm font-normal">
                  {currentData?.ratings?.length} đánh giá
                </span>
              </div>
            </div>
          )}
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Thêm đánh giá..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-[#F3F4F6] dark:divide-neutral-800">
          {currentData?.ratings?.map((item, index) => (
            <CommentListing className="py-4 md:py-8" key={index} data={item} />
          ))}
          <div className="pt-8">
            <ButtonSecondary>Xem thêm</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSelectBox = ({
    isActive,
    onselect,
    title,
    address,
    price,
    change,
    hidden,
  }: {
    hidden?: boolean;
    isActive: string;
    onselect: (title: string) => void;
    title: string;
    address: string;
    price: string;
    change?: any;
  }) => {
    return (
      <div
        className={classNames(
          "flex-row items-start gap-2 px-[15px] py-[10px] border-[0.5px]  rounded-xl",
          isActive === title ? "border-[#0664A4]" : "border-[#DCE2E8]",
          hidden ? "hidden" : "flex"
        )}
      >
        <div className="space-y-[10px]">
          <div className="space-x-2 flex flex-row items-center">
            <span className="text-xl font-semibold text-textPrimary dark:text-neutral-200">
              {title}
            </span>
          </div>
          <div className="font-normal">
            <span className=" text-textPrimary dark:text-neutral-400">
              {address} - <span className="text-[#0664A4]">{price}</span>
            </span>
          </div>
          <span
            className={`underline text-textSecondary text-sm font-semibold cursor-pointer ${
              !change && "hidden"
            }`}
            onClick={() => navigate("/author/information")}
          >
            {address ? "Thay đổi" : "Thêm ngay"}
          </span>
        </div>
      </div>
    );
  };

  const renderTimeLine = () => {
    return (
      <div className="border-[0.5px] border-[#DCE2E8] rounded-xl p-3 flex flex-row space-x-1">
        <div className="text-textSecondary">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left space-y-[10px]">
          <span className="text-textPrimary block xl:text-xl font-semibold dark:text-neutral-200">
            Thời gian thuê xe
          </span>
          <span className="text-base">
            {dateSelected.startTime
              ? moment(dateSelected.startTime).format("hh:mm A") +
                " " +
                moment(dateSelected.startDate).format("DD/MM/yyyy")
              : !dateSelected.startDate || !dateSelected.endDate
              ? "Thêm ngày"
              : "Thêm giờ"}
            {dateSelected.startTime && dateSelected.startDate
              ? " đến " +
                moment(dateSelected.endTime).format("hh:mm A") +
                "," +
                moment(dateSelected.endDate).format("DD/MM/yyyy")
              : ""}
            {/* {formatDate(dateSelected.startDate) || "Thêm ngày"}
            {dateSelected.endDate ? " đến " + formatDate(new Date()) : ""} */}
          </span>
          {/* <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Pick up - Drop off"}
          </span> */}
        </div>
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <div className="rounded-2xl shadow-xl">
        {/* IMAGE SALE */}
        <SaleIcon className="w-full" />
        <div className="listingSectionSidebar__wrap">
          {/* PRICE */}
          <div className="flex justify-between items-center">
            <span className="line-through text-lg text-textSecondary font-semibold">
              {!currentPrice ? (
                <div className="animate-pulse space-y-2">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 w-20"></div>
                </div>
              ) : (
                formatCurrency(100000)
              )}
            </span>
            <span className="text-4xl font-semibold text-colorPrimary flex flex-row items-end">
              {!currentPrice ? (
                <div className="animate-pulse space-y-2">
                  <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-14"></div>
                </div>
              ) : (
                formatCurrency(currentPrice)
              )}
              <span className="ml-1 text-base font-bold text-textSecondary">
                /ngày
              </span>
            </span>
          </div>

          <hr className="color-[#EBEBF0]" />

          {renderTimeLine()}

          {renderSelectBox({
            isActive: methodPickupCar.method,
            onselect: (title) =>
              setMethodPickupCar({
                method: title,
                address: car?.address,
              }),
            title: "Nhận xe tại vị trí",
            address: car?.address,
            price: "Miễn phí",
            // change: "/",
          })}
          {renderSelectBox({
            isActive: methodPickupCar.method,
            onselect: (title) =>
              setMethodPickupCar({
                method: title,
                address: user?.address,
              }),
            title: "Giao xe tận nơi",
            address: user?.address || "Thêm địa chỉ",
            price: !user?.address ? "" : "45.000đ",
            change: "/",
          })}

          <hr className="color-[#EBEBF0]" />
          <FormPay
            type="cancel"
            handleCheckout={handleCheckout}
            currentData={{ ...currentData, ...dateSelected }}
            setModal={setModal}
          />
        </div>
      </div>
    );
  };

  const renderMoreInfo = () => {
    return (
      <div className="space-y-5">
        <TextWithLeftBorder
          title="Thông tin khác"
          className="text-xl font-semibold mb-5"
        />

        <div className="ml-5 space-y-5">
          <div className=" space-y-[10px]">
            <div className="flex flex-row items-center gap-[10px]">
              <LeaseIcon className="w-6" />
              <span className="font-normal text-textPrimary dark:text-neutral-200">
                Giấy tờ thuê xe
              </span>
            </div>

            <ul className="list-inside list-disc text-textSecondary font-normal space-y-[5px]">
              <span>Chọn 1 trong 2 hình thức</span>
              <li>GPLX (đối chiếu) & CCCD (đối chiếu VNeID)</li>
              <li>GPLX (đối chiếu) & Passport (giữ lại)</li>
            </ul>
          </div>
          <div className="space-y-[10px]">
            <div className="flex flex-row items-center gap-[10px]">
              <MoneyIcon className="w-6" />
              <span className="font-normal text-textPrimary dark:text-neutral-200">
                Tài sản thế chấp
              </span>
            </div>

            <ul className="list-inside list-disc text-textSecondary font-normal space-y-[5px]">
              <span>2 triệu tiền cọc thanh toán online</span>
            </ul>
          </div>
          <div className="space-y-[10px]">
            <div className="flex flex-row items-center gap-[10px]">
              <PenIcon className="w-6" />
              <span className="font-normal text-textPrimary dark:text-neutral-200">
                Điều khoản
              </span>
            </div>

            <ul className="list-inside list-disc text-textSecondary font-normal space-y-[5px]">
              <span>Quy định khác: </span>
              <li>Sử dụng xe đúng mục đích</li>
              <li>
                Không sử dụng xe thuê vào mục đích phi pháp trái pháp luật
              </li>
              <li>Không sử dụng xe thuê để cầm cố, thế chấp</li>
              <li>Không hút thuốc, nhả kẹo cao su, xả rác trong xe</li>
              <li>Không chở hàng quốc cấm dễ cháy nổ</li>
            </ul>
          </div>
          <div className="space-y-[10px]">
            <div className="flex flex-row items-center gap-[10px]">
              <ProductManagerIcon className="w-6 h-6" />
              <span className="font-normal text-textPrimary dark:text-neutral-200">
                Phụ phí có thể phát sinh
              </span>
            </div>

            <ul className="list-inside list-disc text-textSecondary font-normal space-y-[5px]">
              <span>Phụ phí khác</span>
              <li>
                Phụ phí phát sinh nếu trả xe trễ, xe không đảm bảo vệ sinh hoặc
                bị ám mùi
              </li>
            </ul>
          </div>
          <div className="space-y-[10px]">
            <div className="flex flex-row items-center gap-[10px]">
              <PolicyIcon className="w-6" />
              <button
                className="font-normal text-textPrimary dark:text-neutral-200"
                // onClick={() => setIsShowModalPolicyCancel(true)}
                // type="button"
              >
                Chính sách hủy chuyến
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={` nc-ListingCarDetailPage `}>
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}
          >
            {requestGetCar?.loading || !currentData ? (
              <div role="status" className="animate-pulse h-full">
                <svg
                  className=" text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            ) : (
              <img
                src={images[0]}
                alt="0"
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
            )}

            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>

          {requestGetCar?.loading || !service ? (
            <div
              role="status"
              className="animate-pulse h-full col-span-2 row-span-2 grid  grid-cols-2 gap-1 sm:gap-2"
            >
              {[...Array(4)].map((_, index) => (
                <svg
                  key={index}
                  className=" text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              ))}
            </div>
          ) : (
            <>
              {images
                .filter((_, i) => i >= 1 && i < 3)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 2 ? "block" : ""
                    }`}
                  >
                    <div className="aspect-w-4 aspect-h-3">
                      <img
                        className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl "
                        src={item || ""}
                        alt="photos"
                        sizes="400px"
                      />
                    </div>

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={handleOpenModalImageGallery}
                    />
                  </div>
                ))}
              {images
                .filter((_, i) => i >= 3 && i < 5)
                .map((item, index) => (
                  <div
                    key={index}
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 2 ? "block" : ""
                    }`}
                  >
                    <div className="aspect-w-4 aspect-h-3">
                      <img
                        className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl "
                        src={item || ""}
                        alt="photos"
                        sizes="400px"
                      />
                    </div>

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={handleOpenModalImageGallery}
                    />
                  </div>
                ))}
            </>
          )}

          <div
            className={`absolute hidden md:${
              requestGetCar?.loading || !service ? "hidden" : "flex"
            } md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10`}
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="h-5 w-5" />

            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Xem tất cả ảnh
            </span>
          </div>
        </div>
      </header>

      {/* MAIn */}
      <main className=" relative z-10 mt-5 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-3/5 space-y-8 lg:pr-10 lg:space-y-10">
          {renderSection1()}

          {renderSection6()}

          {renderMoreInfo()}
        </div>

        {/* SIDEBAR */}
        <div className="block flex-grow mt-14 lg:mt-0 md:w-2/4 xl:w-1/3">
          {/* {renderSidebarDetail()} */}
          <div className="space-y-[10px] p-5 rounded-md bg-[#F5F5F5] dark:bg-neutral-800">
            <div className="flex flex-row justify-between items-center pb-2">
              <div className="flex flex-row items-center gap-1">
                <SecurityIcon className="w-6 h-6" />
                <span className="md:text-lg font-semibold text-colorPrimary">
                  Bảo hiểm thuê xe
                </span>
              </div>
              <button
                className="cursor-pointer"
                onClick={() =>
                  setIsShowFull((prev) => ({
                    ...prev,
                    insurance: !prev.insurance,
                  }))
                }
              >
                <ForwardIcon
                  className={`w-8 h-8 transition-all ${
                    !isShowFull.insurance && `rotate-180`
                  }`}
                />
              </button>
            </div>

            <span
              className={`text-sm text-black dark:text-neutral-400 ${
                !isShowFull?.insurance && "hidden"
              }`}
            >
              Chuyến đi có mua bảo hiểm. Khách thuê bồi thường tối đa 2.000.000đ
              trong trường hợp có sự cố ngoài ý muốn
            </span>
          </div>
          <div className="hidden lg:block mt-10 sticky top-28">
            {renderSidebarPrice()}

            <div className="bg-[#F5F5F5] rounded-lg p-5 mt-6 dark:bg-neutral-800">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row justify-center items-center gap-1">
                  <SecurityIcon className="w-6 h-6" />
                  <span className="text-colorPrimary font-bold">
                    Bảo hiểm tùy chọn (không bắt buộc)
                  </span>
                </div>
                <button
                  className="cursor-pointer"
                  onClick={() =>
                    setIsShowFull((prev) => ({
                      ...prev,
                      secure: !prev.secure,
                    }))
                  }
                >
                  <ForwardIcon
                    className={`w-5 h-5 transition-all ${
                      !isShowFull.secure && `rotate-180`
                    }`}
                  />
                </button>
              </div>

              <div
                className={`mt-2 space-y-3 ${!isShowFull?.secure && "hidden"}`}
              >
                {[
                  {
                    id: 1,
                    title: "Bảo hiểm nội thất",
                    price: 30000,
                  },
                  {
                    id: 2,
                    title: "Bảo hiểm ngoại thất",
                    price: 30000,
                  },
                  {
                    id: 3,
                    title: "Bảo hiểm Premium",
                    price: 30000,
                  },
                ].map((item) => {
                  return (
                    <div
                      className="flex flex-row justify-between items-center"
                      key={item?.id}
                    >
                      <div className="space-y-1">
                        <div className="flex flex-row items-center gap-1">
                          <span className="text-sm font-normal">
                            {item?.title}
                          </span>
                          <button>
                            <InformationCircleIcon className="w-4 h-4 text-textSecondary" />
                          </button>
                        </div>
                        <span className="text-colorPrimary font-bold">
                          {formatCurrency(item?.price)}/ngày
                        </span>
                      </div>
                      <button onClick={() => handleSelectSecure(item?.id)}>
                        {secureSelected?.includes(item?.id) ? (
                          <CheckIcon className="h-5" />
                        ) : (
                          <NonCheckIcon className="h-5" />
                        )}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="flex flex-row items-center space-x-1 justify-center mt-6 text-textSecondary cursor-pointer"
              onClick={() =>
                setModal((prev) => ({
                  ...prev,
                  report: true,
                }))
              }
            >
              <FlatIcon className="w-5 h-5" />
              <span className="font-semibold underline">Báo cáo xe</span>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ListingCarDetail;
