import { ApiUtils } from "./index";
import { PATH } from "./path";

const COMPANY_ID = process.env.REACT_APP_ID_COMPANY;

export const getCarsByPageApi = async (
  warehouseId: string,
  offset: number,
  limit: number
) => {
  const URL =
    PATH.get_cars +
    `&company_id=${COMPANY_ID}` +
    `&warehouse=${warehouseId}` +
    `&offset=${offset}` +
    `&limit=${limit}` +
    "&order=services.id%20desc" +
    `&filters=service_type%20eq%20%22single%22%20`;
  return await ApiUtils.request(URL);
};

export const getCarById = async (slug: string) =>
  await ApiUtils.request(
    PATH.get_car + `&filters=slug%20eq%20%22${slug}%22%20`
  );

export const getCarsFlashsaleByPageApi = async (
  warehouseId: string,
  offset: number,
  limit: number
) => {
  const URL =
    PATH.get_cars +
    `&company_id=${COMPANY_ID}` +
    `&warehouse=${warehouseId}` +
    `&offset=${offset}` +
    `&limit=${limit}` +
    "&order=services.id%20desc" +
    `&filters=service_type%20eq%20%22single%22%20&promotion_type=flash_sales`;
  return await ApiUtils.request(URL);
};
export const getCarsNearByPageApi = async (
  warehouseId: string,
  offset: number,
  limit: number,
  lat: number,
  log: number
) => {
  const URL =
    PATH.get_cars +
    `&company_id=${COMPANY_ID}` +
    `&warehouse=${warehouseId}` +
    `&offset=${offset}` +
    `&limit=${limit}` +
    `&latitude=${lat}` +
    `&longitude=${log}` +
    `&order=services.id%20desc` +
    `&filters=service_type%20eq%20%22single%22%20`;
  return await ApiUtils.request(URL);
};
export const getCarsNewManufactureByPageApi = async (
  warehouseId: string,
  offset: number,
  limit: number
) => {
  const URL =
    PATH.get_cars +
    `&company_id=${COMPANY_ID}` +
    `&warehouse=${warehouseId}` +
    `&offset=${offset}` +
    `&limit=${limit}` +
    "&sort_by_year=desc" +
    `&filters=service_type%20eq%20%22single%22%20`;
  return await ApiUtils.request(URL);
};

export const getCarFiltersApi = async (warehouseId: string, query: string) => {
  const URL =
    PATH.get_cars +
    `&company_id=${COMPANY_ID}` +
    `&warehouse=${warehouseId}` +
    `&offset=0&limit=1000` +
    query;
  return await ApiUtils.request(URL);
};
