import React, { useImperativeHandle, useState } from "react";

import Loading from "@static/icons/loading.png";
// import { IonSpinner } from "@ionic/react";
import "./style.css";

export const globalLoadingRef = React.createRef<any>();

export const globalLoading = {
  show: () => {
    globalLoadingRef?.current?.show();
  },
  hide: () => {
    globalLoadingRef?.current?.hide();
  },
};

export const GlobalLoading = React.forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const show = () => {
    setLoading(true);
  };

  const hide = () => {
    setLoading(false);
  };

  useImperativeHandle(ref, () => {
    return { show: show, hide: hide };
  });

  return loading ? (
    <>
      <div className="overlay">
        <div id="container">
          {/* <IonSpinner
            name="circles"
            color={"light"}
            style={{ height: 36, width: 36 }}
          ></IonSpinner> */}
          <span className="font-base body-medium-regular text-secondary ">
            Đang xử lý...
          </span>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
});
