//@ts-nocheck

import React, { FC, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import GoogleMapReact from "google-map-react";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import CarCardH from "components/CarCardH/CarCardH";
import SkeletonCarCardH from "components/CarCardH/SkeletonCarCardH";
import ModalFilterMobile from "components/ModalFilterMobile";
import Heading2 from "components/Heading/Heading2";
import NotFoundCar from "components/NotFoundCar";

import ButtonClose from "shared/ButtonClose/ButtonClose";
import Pagination from "shared/Pagination/Pagination";
import { useFilter } from "atom/filter/useFilter";
import { useWarehouse } from "atom/warehouse/useWarehouse";
import { useCar } from "atom/car/useCar";
import MapBoxComponent from "components/MapBoxComponent";
import Checkbox from "shared/Checkbox/Checkbox";

export interface ListCarPageProps {}

const pageSize = 8;

const ListCarPage: FC<ListCarPageProps> = () => {
  const { car, requestGetCarsByPage } = useCar();
  const { warehouseId } = useWarehouse();
  const { requestFilterCar, filter } = useFilter();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [center, setCenter] = useState({
    lat: 10.8231,
    lng: 106.6297,
  });

  const totalCar = useMemo(() => {
    return requestGetCarsByPage?.data?.meta?.paging?.total;
  }, [requestFilterCar]);

  useEffect(() => {
    if (warehouseId) setData(car.data);
    // setCenter({
    //   lat: car.data[0]?.car?.latitude,
    //   lng: car.data[0]?.car?.longitude,
    // });
  }, [car, warehouseId]);

  useEffect(() => {
    requestGetCarsByPage
      .runAsync(pageSize * (currentPage - 1))
      .then((res: any) => setData(res.data));
  }, [currentPage]);

  // const MyLocation = ({ lat, lng }) => {
  //   return <MapPinIcon className="h-8 text-orange-400" />;
  // };

  const totalPage = useMemo(() => Math.ceil(totalCar / pageSize), [totalCar]);

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Helmet>
        <title>3GO | Danh sách xe</title>
        <meta
          name="description"
          content="Xem danh sách xe trên nền tảng 3GO, nơi bạn có thể tìm thuê các loại xe phù hợp với nhu cầu của bạn."
        />
        <meta property="og:title" content="3GO - Danh sách xe" />
        <meta
          property="og:description"
          content="Xem danh sách xe trên nền tảng 3GO, nơi bạn có thể tìm thuê các loại xe phù hợp với nhu cầu của bạn."
        />
        <meta
          property="og:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
        <meta
          property="og:url"
          content={window.location.href + "/listing-car"}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="3GO - Danh sách xe" />
        <meta
          name="twitter:description"
          content="Xem danh sách xe trên nền tảng 3GO, nơi bạn có thể tìm thuê các loại xe phù hợp với nhu cầu của bạn."
        />
        <meta
          name="twitter:image"
          content="https://sapo.aimini.vn/uploads/prizes/prizes_1jkuvr8ibczf0zzqfnzh.png"
        />
      </Helmet>

      <div className="relative flex min-h-screen">
        {/* CARDSSSS */}
        <div
          className={classNames(
            "min-h-screen w-full p-2 xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8"
            // showFullMapFixed && "hidden"
          )}
        >
          <div className="flex flex-row items-center justify-start py-4 space-x-5">
            <ModalFilterMobile
              setCars={setData}
              currentTab="all"
              setIsFilter={setIsFilter}
            />
            {filter?.address?.province?.title ? (
              <Heading2
                heading={filter?.address?.province?.title}
                subHeading={`Tìm thấy ${totalCar || 0} xe`}
              />
            ) : (
              <span className="text-4xl font-semibold">
                Sử dụng bộ lọc để tìm kiếm xe
              </span>
            )}
          </div>
          <div className="grid grid-cols-1 gap-8">
            {!data?.length ||
            requestFilterCar.loading ||
            requestGetCarsByPage.loading ? (
              [...Array(4)].map((_, i) => <SkeletonCarCardH key={i} />)
            ) : (!data?.length && !requestFilterCar.loading) ||
              requestGetCarsByPage.loading ? (
              <NotFoundCar />
            ) : (
              data?.map((item) => (
                <div
                  key={item.xid}
                  onMouseEnter={() => setCurrentHoverID((_) => item.xid)}
                  onMouseLeave={() => setCurrentHoverID((_) => -1)}
                >
                  <CarCardH car={item} />
                </div>
              ))
            )}
          </div>
          <div
            className={classNames(
              "flex mt-16 justify-center items-center",
              requestFilterCar?.data?.meta?.paging?.total <= pageSize &&
                "hidden"
            )}
          >
            <Pagination
              totalPage={totalPage}
              currentPage={currentPage}
              handleChangePage={(page) => handleChangePage(page)}
              className={(totalPage <= 1 || isFilter) && "hidden"}
            />
          </div>
        </div>

        <div
          className="flex xl:hidden items-center justify-center fixed bottom-14 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30 space-x-3 text-sm cursor-pointer"
          onClick={() => setShowFullMapFixed(true)}
        >
          <i className="text-lg las la-map"></i>
          <span>Hiện bản đồ</span>
        </div>

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {/* <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white shadow-xl z-10 rounded-2xl min-w-max">
              <button onClick={() => setShowFullMapFixed(true)}>
                Hiển thị full màn hình
              </button>
            </div> */}

            <GoogleMapReact
              key={JSON.stringify(data)}
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
              }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={12}
              center={center}
            >
              {data?.map((item) => (
                <AnyReactComponent
                  isSelected={currentHoverID === item?.xid}
                  key={item?.xid}
                  lat={item?.car?.latitude}
                  lng={item?.car?.longitude}
                  car={item}
                />
              ))}
            </GoogleMapReact>
            {/* <MapBoxComponent
              key={JSON.stringify(car?.data)}
              center={center}
              currentHoverID={currentHoverID}
              data={data}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCarPage;
