"use client";

import React, { FC, useState } from "react";
import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
import userIcon from "images/icons/solar_user-linear.svg";
import carIcon from "images/icons/car1.svg";
import clockIcon from "images/icons/clock.svg";
import { useFilter } from "atom/filter/useFilter";

export type SearchTab = "Stays" | "Tự lái" | "Xe có tài xế" | "Thuê xe dài hạn";

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: any;
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Tự lái",
  currentPage,
}) => {
  const { filter, setFilter } = useFilter();

  const tabs: {
    icon: string;
    title: SearchTab;
    key: string;
  }[] = [
    {
      icon: carIcon,
      title: "Tự lái",
      key: "selfDriving",
    },
    {
      icon: userIcon,
      title: "Xe có tài xế",
      key: "withDriver",
    },
    {
      icon: clockIcon,
      title: "Thuê xe dài hạn",
      key: "rental",
    },
  ];

  const renderTab = () => {
    return (
      <ul className="flex mx-auto hiddenScrollbar w-100 rounded-t-md overflow-hidden z-10 ">
        {tabs.map((tab) => {
          const active = tab.key === filter.serviceType;
          return (
            <li
              onClick={() =>
                setFilter((prev) => ({
                  ...prev,
                  serviceType: tab.key,
                }))
              }
              className={`flex-shrink-0 flex flex-row items-center space-x-2 cursor-pointer text-sm lg:text-base w-[173px] justify-center py-3 font-semibold ${
                active
                  ? "dark:shadow-2xl bg-white dark:bg-neutral-800 "
                  : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400 bg-[#EDEEF0] dark:shadow-2xl dark:bg-neutral-700 "
              } `}
              key={tab.title}
            >
              <img
                src={tab.icon}
                alt=""
                className="text-textPrimary w-[17px]"
              />
              <span>{tab.title}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    switch (filter.serviceType) {
      case "selfDriving":
        return <ExperiencesSearchForm />;
      case "withDriver":
        return <ExperiencesSearchForm readOnly={false} />;
      case "rental":
        return <ExperiencesSearchForm readOnly={false} />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`nc-HeroSearchForm w-full max-w-6xl py-5 lg:py-0 mx-auto flex flex-col justify-center  ${className}`}
    >
      {renderTab()}
      {renderForm()}
    </div>
  );
};

export default HeroSearchForm;
